export const ROOT = "/";
export const AUTHORIZATION = "/sign_in";
export const REGISTRATION = "/sign_up";
export const PERSONAL_ACCOUNT = "/personal_account";
export const CREATE_AUDIO_TOUR = "/create_audio_tour";
export const All_AUDIO_TOUR = "/all_audio_tour";
export const USER_AUDIO_TOUR = "/user_audio_tour";
export const USER_COURSES = "/user_courses";
export const AUDIO_TOUR = "/audio_tour";
export const COURSE = "/course";
export const CATEGORIES_COURSE = "/categories_course";
export const AUDIO_TOUR_BOOKMARK = "/audio_tour_bookmark";
export const COURSE_BOOKMARK = "/course_bookmark";
export const COURSE_CATEGORY = "/course_category";
export const CATEGORIES_TOUR = "/categories_tour";
export const WATCH_TOUR = "/watch_tour";
export const WATCH_COURSE = "/watch_course";
export const WATCH_STEP_TOUR = "/watch_step_tour";
export const CREATE_COURSE = "/create_course";
export const All_COURSES = "/all_courses";
export const CONTACT = "/contact";
export const ALL_USERS_CONTROL = "/all_users_control";
